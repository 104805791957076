const config = {
  baseUrl: '/',
  //imageUrl: "http://localhost:3008/images",
  //apiUrl: "http://localhost:3008/api",
  imageUrl: "https://fastener-front.lyonsdemo.in/apis/api/uploads",
  apiUrl: "https://fastener-front.lyonsdemo.in/apis/api",
  superAdmin:1,
  paging_size:20
}
export default config;
